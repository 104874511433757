import * as React from "react";
import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import FormationsCollapseTable from "./CollapseTable";

const compLoad = () => {
    if (window.location.href.includes('#consulting')) {
        return "dtalents-consulting";
    } else {
        return "dtalents-formations";
    }
}
export default function AppHome() {
    const [alignment, setAlignment] = React.useState(compLoad);
    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        setAlignment(newAlignment);
    };
    return (
        <>
            <Box display="flex" justifyContent="center" alignItems="center">
                <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange}
                                   aria-label="Platform">
                    <ToggleButton value="dtalents-formations" href={'#formations'}>Dtalents Formations</ToggleButton>
                    <ToggleButton value="dtalents-consulting" href={'#consulting'}>Dtalents Consulting</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {alignment == "dtalents-formations" ? <FormationsCollapseTable societe={'formations'}/> :
                <p><FormationsCollapseTable societe={'consulting'}/></p>}
        </>
    );
}
